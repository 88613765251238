<div class="dept-dd-container row">
    <label class="col-12">{{'MEMOS.DEPARTMENT' | translate}}</label>
    <div class="dept-dd-dropdown col-md-6"
        *ngFor="let dropdown of dropdownList; let i = index">
        <ng-select *ngIf="!refreshDropdown"
            class="w-100 m-t-sm-3"
            [items]="dropdown"
            [searchFn]="customSearchDepartmentFn"
            bindValue="id"
            [ngClass]="i > 1 ? 'custom-mt': ''"
            [notFoundText]="'SELECT.No items found'|translate"
            placeholder="{{'MEMOS.SELECT' | translate}}"
            [(ngModel)]="selectedDepartmentID[i] ? selectedDepartmentID[i]  : currentDepartmentId"
            (change)="selectedDepartment(i, $event)"
            (clear)="clearDepartment(i)">
          <ng-template ng-label-tmp
            let-item="item">
            {{ translated === 'en' ? item.department_name_en: item.department_name }}
            ({{ item.department_code }})
          </ng-template>

          <ng-template ng-option-tmp
            let-item="item">
            {{ translated === 'en' ? item.department_name_en: item.department_name }}
            ({{ item.department_code }})
          </ng-template>
        </ng-select>
        <div *ngIf="i < dropdownList.length - 1"
            class="dept-dd-separator">/</div>
    </div>

    <div class="col-md-6 text-right-sm m-t-sm-3"
         [style.--c]="themeList?.header_table_color"
        [ngClass]="dropdownList.length > 1 ? 'mt-3':''">
        <button class="btn btn-clear ml-2 p-btn"
            *ngIf="showNextLevelButton"
            (click)="showNextLevelButtonClicked()">{{dropdownList.l}}
            {{'DEPARTMENT.SELECT-SUB-DEPARTMENT' | translate}}</button>
        <button class="btn btn-clear ml-2 p-btn"
            (click)="clearData()">{{clearLabel | translate}}</button>
    </div>
</div>
