import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import {
  ITreeOptions,
  TreeComponent,
} from '@circlon/angular-tree-component';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { DepartmentService } from 'src/app/modules/department/department.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { Store } from '@ngxs/store';
import { ErrorNotification } from 'src/app/store/memo/memo.actions';

@Component({
  selector: 'app-tree-dropdown',
  templateUrl: './tree-dropdown.component.html',
  styleUrls: ['./tree-dropdown.component.scss'],
})
export class TreeDropdownComponent implements OnInit, OnChanges {
  @ViewChild('treeRoot', { static: false })
  private tree: TreeComponent;

  @Input() multiple = true;
  @Input() model: { id: number; name: string }[] | number[] = [];

  @Input() placeholder = '';
  @Input() form: FormControl;
  @Input() defaultValue;
  @Input() disable = false;

  @Input() isAllDepartment = false;
  @Output() isAllDepartmentChange = new EventEmitter();

  @Output() modelChange = new EventEmitter<
    number[] | number | string
  >();
  @Input() headers: any;
  @Input() defaultAllDepartment = false;

  isShow = false;
  options: ITreeOptions = {
    useCheckbox: true,
    useTriState: false,
  };

  nodes;
  items = {};

  // for some projects, if deparments is very large, I recommend to disable this.
  expandNodeWhenSelect = true;

  loading = false;
  formDirective: FormGroupDirective;
  allNodesLength = 0;

  ALL_DATA = {
    display_name: 'แผนกทั้งหมด',
    display_name_en: 'All departments',
    id: 'all',
    department_name: 'แผนกทั้งหมด',
    department_name_en: 'All departments',
    children: [],
  };
  loadingSpinner: boolean;
  checkAllDepartment = false;
  httpHeaders;
  processingNodeClickEvent = false;

  constructor(
    private departmentService: DepartmentService,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    private spinner: SpinnerService,
    private authenticationService: AuthenticationService,
    private store: Store,
  ) {
    this.httpHeaders = this.authenticationService.httpHeader;
  }

  itemsIsEmpty() {
    return Object.keys(this.items).length === 0;
  }

  getHeader() {
    if (this.headers) {
      return new HttpHeaders().set('Authorization', this.headers);
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model) {
      this.model = changes.model.currentValue;

      if (this.model.length > 0) {
        // if 'this.tree.treeModel' is null, it means that this function is called on the first time,
        //    It will process in function 'ngOnInit'
        if (this.tree?.treeModel) {
          const nodeList = [];
          this.items = {};
          this.model.forEach((element) => {
            const id = element.id ? element.id : element;
            const node = this.tree.treeModel.getNodeById(id);
            nodeList.push(node);
          });
          this.nodeChecked(nodeList, true, false, false);
        }
      } else {
        this.items = {};
      }
    }
    if (
      changes.defaultAllDepartment &&
      !changes.defaultAllDepartment.firstChange
    ) {
      if (!this.defaultAllDepartment) {
        this.checkAllDepartment = false;
        this.tree.treeModel.selectedLeafNodeIds = Object.assign({});
      }
    }
  }

  ngOnInit(): void {
    const header = this.getHeader();
    const params = {
      page_size: 1000,
    };
    this.departmentService
      .getDepartmentTree(params, header)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(
        (res) => {
          this.nodes = _.cloneDeep(res);
          this.getNodeNumber();
          this.nodes.unshift(this.ALL_DATA);
          this.loading = true;
          if (this.model?.length) {
            this.mapNameFromModel();
          } else if (this.form && this.form.value?.length) {
            this.model = this.form.value;
            this.mapNameFromModel();
          } else if (this.defaultValue) {
            // I assume that defaultValue is list of department ids.
            this.items = {};
            this.defaultValue.forEach((id) => {
              const element = this.nodes.find((obj) => obj.id === id);
              if (element) {
                this.items[element.id] = element;
              }
            });
          }
          if (this.defaultAllDepartment) {
            this.mapNameFromModel();
          }
        },
        (error) => {
          this.store.dispatch(new ErrorNotification(error));
        },
      );
  }

  getNodeNumber(): void {
    this.nodes
      .filter((obj) => obj.id !== this.ALL_DATA.id)
      .forEach((obj) => {
        this.allNodesLength += obj.descendants_ids.length + 1;
      });
  }

  mapNameFromModel(): void {
    // this will get selected department list
    const header = this.getHeader();
    this.loadingSpinner = true;
    const params = {
      id_list: this.isAllDepartment ? '' : this.model.join('|'),
      page_size: 1000,
    };
    this.departmentService
      .getDepartmentList(params, header)
      .subscribe(
        (res: any) => {
          this.items = {};
          res.results.forEach((element) => {
            this.items[element.id] = element;
          });
          this.loadingSpinner = false;
          if (this.isAllDepartment) {
            this.handleSelectAll(true);
            this.emitData();
          }
        },
        (error) => {
          this.store.dispatch(new ErrorNotification(error));
          this.loadingSpinner = false;
        },
      );
  }

  nodeClickEvent(node, check, children = true) {
    // there are case that, in function '_recursiveProcessNode' will check node and will call event to this function
    //    which will process duplicate event and make website slow.
    if (this.processingNodeClickEvent) {
      return;
    }

    this.processingNodeClickEvent = true;
    try {
      this.nodeChecked([node], check, children, true);
    } finally {
      this.processingNodeClickEvent = false;
    }
  }

  nodeChecked(nodeList, check, children, emitDataEvent): void {
    if (nodeList.find((node) => node.data.id === this.ALL_DATA.id)) {
      this.handleSelectAll(check);
    } else {
      const nodeResultDict = {};
      nodeList.forEach((node) => {
        this._recursiveProcessNode(
          node,
          check,
          children,
          nodeResultDict,
        );
      });

      // this code will directly check tree nodes.
      // NOTE: DO NOT USE FUNCTION 'node.setIsSelected' or 'this.tree.treeModel.setSelectedNode' because it is very slow.
      // More info: https://github.com/CirclonGroup/angular-tree-component/issues/604
      this.tree.treeModel.selectedLeafNodeIds = Object.assign(
        {},
        this.tree.treeModel.selectedLeafNodeIds,
        nodeResultDict,
      );

      if (check) {
        this.expandAllChain(nodeResultDict);
      }

      this.checkIfSelectedAll();
    }
    if (emitDataEvent) {
      this.emitData();
    }
  }

  handleSelectAll(check: boolean): void {
    if (check) {
      this.checkAllDepartment = true;
      this.isAllDepartmentChange.emit(true);
      const nodeResultDict = {};
      this.items = {};
      this.tree.treeModel.doForAll((obj) => {
        this._recursiveProcessNode(obj, check, true, nodeResultDict);
      });

      // this code will directly check tree nodes.
      // NOTE: DO NOT USE FUNCTION 'node.setIsSelected' or 'this.tree.treeModel.setSelectedNode' because it is very slow.
      // More info: https://github.com/CirclonGroup/angular-tree-component/issues/604
      this.tree.treeModel.selectedLeafNodeIds = Object.assign(
        {},
        this.tree.treeModel.selectedLeafNodeIds,
        nodeResultDict,
      );

      this.expandAllChain(nodeResultDict);
    } else {
      this.checkAllDepartment = false;
      this.isAllDepartmentChange.emit(false);
      this.tree.treeModel.selectedLeafNodeIds = Object.assign({});
      this.clearValue();
    }
  }

  closeAllDepartment(): void {
    this.handleSelectAll(false);
    const value = [];
    this.modelChange.emit(value);
    if (this.form) {
      this.form.setValue(value);
    }
  }

  emitData(): void {
    const selectedIdList = Object.keys(this.items).map(Number);
    let value: any = selectedIdList;
    if (!this.multiple) {
      value = selectedIdList[0] || '';
    }
    this.modelChange.emit(value);
    if (this.form) {
      this.form.setValue(value);
    }
  }

  _recursiveProcessNode(node, check, children, nodeResultDict): void {
    if (node.data.id !== this.ALL_DATA.id) {
      nodeResultDict[node.data.id] = check;
    }

    if (check) {
      if (node.data.id !== this.ALL_DATA.id) {
        this.items[node.data.id] = node.data;
      }
    } else {
      if (node.data.id in this.items) {
        delete this.items[node.data.id];
      }
    }

    if (children) {
      node.children
        .filter((obj) => obj !== undefined)
        .forEach((element) => {
          this._recursiveProcessNode(
            element,
            check,
            children,
            nodeResultDict,
          );
        });
    }
  }

  checkIfSelectedAll(): boolean {
    const itemsLength = Object.keys(this.items).length;
    const isSelectAll =
      itemsLength > 0 && itemsLength === this.allNodesLength;

    this.checkAllDepartment = isSelectAll;
    this.isAllDepartmentChange.emit(isSelectAll);

    // check "all department" node
    // this code will directly check tree nodes.
    // NOTE: DO NOT USE FUNCTION 'node.setIsSelected' or 'this.tree.treeModel.setSelectedNode' because it is slow and
    //       will trigger checked event.
    // More info: https://github.com/CirclonGroup/angular-tree-component/issues/604
    const node = this.tree.treeModel.getNodeById(this.ALL_DATA.id);
    this.tree.treeModel.selectedLeafNodeIds = Object.assign(
      {},
      this.tree.treeModel.selectedLeafNodeIds,
      { [node.id]: isSelectAll },
    );

    return isSelectAll;
  }

  onActivate(event): void {
    this.tree.treeModel.setExpandedNode(event.node, true);
  }

  deleteItem(id: string | number): void {
    id = Number(id);
    if (isNaN(id)) {
      return;
    }
    const node = this.tree.treeModel.getNodeById(id);
    this.nodeClickEvent(node, false, false);
  }

  showDropdown(): void {
    this.isShow = !this.isShow;
    this.cd.detectChanges();
    if (this.isShow && this.tree) {
      const nodeList = [];
      for (const key in this.items) {
        const node = this.tree.treeModel.getNodeById(key);
        if (node) {
          nodeList.push(node);
        }
      }
      this.nodeChecked(nodeList, true, false, false);
    }
  }

  expandAllChain(nodeResultDict = {}) {
    if (!this.expandNodeWhenSelect) {
      return;
    }
    const cloneDict = Object.assign({}, nodeResultDict);
    for (const key in cloneDict) {
      cloneDict[key] = true;
    }
    this.tree.treeModel.expandedNodeIds = Object.assign(
      {},
      this.tree.treeModel.expandedNodeIds,
      cloneDict,
    );
  }

  clearValue(): void {
    this.items = {};
    this.modelChange.emit('');
    if (this.form && this.form.value) {
      this.form.setValue('');
    }
  }
}
