import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  CeleryImportState,
  CeleryTaskStatus,
} from '../../models/drf-response.models';
import { ManageTypesUploadMemoService } from '../../../modules/manage-types-upload-memo/shared/manage-types-upload-memo.service';
import { buildErrorHTMLMessage } from '../../utils/common.util';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-import-progress',
  templateUrl: './import-progress.component.html',
  styleUrls: ['./import-progress.component.scss'],
})
export class ImportProgressComponent implements OnInit, OnDestroy {
  celeryImportState = CeleryImportState;

  sendingTaskInterval;
  sendingTaskDetail: CeleryTaskStatus;

  // refresh less than 1-2 secs is too much. it should be more than 5 secs.
  @Input() timeout = 5000;
  @Input() resultTitle: string;
  @Input() taskId: string;
  @Input() resultState: string;
  @Output() sent = new EventEmitter<CeleryImportState>();
  @Output() downloadExcel = new EventEmitter();
  subscription: Subscription[] = [];
  constructor(private apiService: ManageTypesUploadMemoService) {}

  ngOnInit(): void {
    this.cancelSendingTask();
    this.sendingTaskDetail = {
      _state: CeleryImportState.Pending,
    };
    this.subscribe(this.taskId);
  }

  subscribe(taskId: string): void {
    this.sendingTaskInterval = undefined;
    this.sendingTaskDetail = {
      _state: CeleryImportState.Pending,
    };

    this.subscribeSendingTaskStatus(taskId);
  }

  subscribeSendingTaskStatus(taskId: string): void {
    this.sendingTaskInterval = setInterval(() => {
      const subscription = this.apiService
        .getCeleryTaskStatus(taskId)
        .subscribe((res) => {
          // update progress
          this.sendingTaskDetail = res;

          // unsubscribe when success or failure
          if (
            res._state === CeleryImportState.Success ||
            res._state === CeleryImportState.Failure
          ) {
            if (res.excel) {
              this.downloadExcel.emit(res.excel);
            }
            this.sent.emit(res._state);
            clearInterval(this.sendingTaskInterval);
          }

          if (res._state === CeleryImportState.Failure) {
            res.detail = buildErrorHTMLMessage(res.detail);
          }
        });
      this.subscription.push(subscription);
    }, this.timeout);
  }

  cancelSendingTask(): void {
    if (this.sendingTaskInterval) {
      clearInterval(this.sendingTaskInterval);
    }
  }

  replaceUnderscore(text: string, withChar = ' '): string {
    return text.split('_').join(withChar);
  }

  ngOnDestroy(): void {
    this.cancelSendingTask();
    if (this.subscription) {
      this.subscription?.forEach((item) => {
        try {
          item.unsubscribe();
        } catch (_) {}
      });
    }
  }

  get resultKeys(): string[] {
    if (
      !this.sendingTaskDetail.rows ||
      !this.sendingTaskDetail.rows.length
    ) {
      return [];
    }

    return Object.keys(this.sendingTaskDetail.rows[0]);
  }

  get currentStateProgressType(): string {
    if (this.sendingTaskDetail._state === CeleryImportState.Success) {
      return 'success';
    }
    if (this.sendingTaskDetail._state === CeleryImportState.Failure) {
      return 'danger';
    }
    return 'info';
  }
}
