<div class="w-100 d-flex">
  <div class="d-flex justify-content-center align-items-center">
    <img [src]="(imageUrl | secure | async) || 'assets/images/person.png'"
      [alt]="mainContent"
      style="width: 3.2rem;height: 3.2rem;border-radius: 50%;object-fit: cover;">
  </div>

  <div class="w-100 flex-grow-1 d-flex flex-column ml-3">
    <span class="text-primary"
      style="font-size: 1.2rem">{{mainContent}}</span>
    <span class="text-muted mt-1"
      style="font-size: 1rem">{{subContent}}</span>
  </div>
</div>
