import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-profile-item',
  templateUrl: './profile-item.component.html',
  styleUrls: ['./profile-item.component.scss'],
})
export class ProfileItemComponent {
  @Input() imageUrl: string;
  @Input() mainContent: string;
  @Input() subContent: string;
}
