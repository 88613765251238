import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { HomeComponent } from './core/components/home/home.component';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { LoginComponent } from './core/components/login/login.component';
import { AdfsCallbackComponent } from './core/components/adfs-callback/adfs-callback.component';
import { MaintenanceComponent } from './core/components/maintenance/maintenance.component';
import { Component } from '@angular/core';
import { PermissionNotFoundComponent } from './shared/components/permission-not-found/permission-not-found.component';
import { ViewerGuard } from './core/guards/viewer.guard';
import { EContractGuard } from './core/guards/econtract.guard';

@Component({
  selector: 'app-media-window-component',
  template: 'file is in download folder',
  styleUrls: [],
})
class MediaWindowComponent {}
const routes: Routes = [
  {
    canActivate: [AuthGuard],
    data: [
      'General',
      'Auditor',
      'IT',
      'Master Admin',
      'Admin',
      'Contract Management',
    ],
    path: '',
    component: HomeComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'adfs-callback',
    component: AdfsCallbackComponent,
  },
  {
    path: 'window-media',
    component: MediaWindowComponent,
  },
  {
    path: 'profile-detail',
    data: [
      'General',
      'Auditor',
      'IT',
      'Master Admin',
      'Admin',
      'Contract Management',
    ],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/profile/profile.module').then(
        (m) => m.ProfileModule,
      ),
  },
  {
    path: 'news-feed',
    data: [
      'General',
      'Auditor',
      'IT',
      'Master Admin',
      'Admin',
      'Contract Management',
    ],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/new-feed/new-feed.module').then(
        (m) => m.NewFeedModule,
      ),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('../app/modules/register/register.module').then(
        (m) => m.RegisterModule,
      ),
  },
  {
    path: 'configuration',
    data: ['Master Admin', 'Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/setting/setting.module').then(
        (m) => m.SettingModule,
      ),
  },
  {
    path: 'users',
    data: ['Master Admin', 'Admin', 'IT'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/users/users.module').then(
        (m) => m.UsersModule,
      ),
  },
  {
    path: 'department',
    data: ['Master Admin', 'Admin', 'IT'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/department/department.module').then(
        (m) => m.DepartmentModule,
      ),
  },
  {
    path: 'manage-types-upload-memo',
    data: ['Master Admin', 'Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/manage-types-upload-memo/manage-types-upload-memo.module'
      ).then((m) => m.ManageTypesUploadMemoModule),
  },
  {
    path: 'manage-csr',
    data: ['Master Admin', 'Admin', 'IT'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/manage-csr/manage-csr.module').then(
        (m) => m.ManageCsrModule,
      ),
  },
  {
    path: 'budget-overview/budget-detail',
    data: ['Master Admin', 'Admin', 'General', 'IT', 'Auditor'],
    loadChildren: () =>
      import(
        '../app/modules/budget-detail/budget-detail.module'
      ).then((m) => m.BudgetDetailModule),
  },
  {
    path: 'budget-overview',
    data: ['Master Admin', 'Admin', 'General', 'IT', 'Auditor'],
    canActivate: [ViewerGuard],
    loadChildren: () =>
      import(
        '../app/modules/budget-management/budget-management.module'
      ).then((m) => m.BudgetManagementModule),
  },
  {
    path: 'kpi-dashboard',
    data: ['Master Admin', 'Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule,
      ),
  },
  {
    path: 'usage-dashboard',
    data: ['Admin', 'Master Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/usage/usage.module').then(
        (m) => m.UsageModule,
      ),
  },
  {
    path: 'contract-package',
    data: ['Master Admin', 'Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modules/contract-dashboard/contract-dashboard.module'
      ).then((m) => m.ContractDashboardModule),
  },
  {
    path: 'memo-report',
    data: ['Admin', 'Master Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/memo-report/memo-report.module').then(
        (m) => m.MemoReportModule,
      ),
  },
  {
    path: 'general-report',
    data: ['Master Admin', 'Admin', 'General', 'Auditor'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/general-report/general-report.module')
        .then()
        .then((m) => m.GeneralReportModule),
  },
  {
    path: 'operation-log',
    data: ['Admin', 'Master Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/modules/operation-log/operation-log.module'
      ).then((m) => m.OperationLogModule),
  },
  {
    path: 'pending-approval',
    data: ['General', 'Auditor', 'Contract Management'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/executive/executive.module').then(
        (m) => m.ExecutiveModule,
      ),
  },
  {
    path: 'approval',
    loadChildren: () =>
      import('../app/modules/approval/approval.module').then(
        (m) => m.ApprovalModule,
      ),
  },
  {
    path: 'upload-evidences',
    loadChildren: () =>
      import('../app/modules/evidence/evidence.module').then(
        (m) => m.EvidenceModule,
      ),
  },
  {
    path: 'loa',
    data: ['Master Admin', 'Admin', 'IT'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/loa/loa.module').then(
        (m) => m.LoaModule,
      ),
  },
  {
    path: 'cc-group',
    data: ['Master Admin', 'Admin', 'IT'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/cc-group/cc-group.module').then(
        (m) => m.CcGroupModule,
      ),
  },
  {
    path: 'master-data',
    data: ['Master Admin', 'Admin'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/master-data/master-data.module').then(
        (m) => m.MasterDatModule,
      ),
  },
  {
    path: 'memos',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/memos/memo.module').then((m) => m.MemoModule),
  },
  {
    path: 'contract',
    data: ['Contract Management'],
    canActivate: [EContractGuard],
    loadChildren: () =>
      import('./modules/contract/contract.module').then(
        (m) => m.ContractModule,
      ),
  },
  {
    path: 'certificate',
    data: ['Master Admin', 'Admin', 'IT'],
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/certificate/certificate.module').then(
        (m) => m.CertificateModule,
      ),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import(
        '../app/modules/reset-password/reset-password.module'
      ).then((m) => m.ResetPasswordModule),
  },

  { path: 'website-maintenance', component: MaintenanceComponent },

  // otherwise redirect to home
  { path: 'page-not-found', component: PageNotFoundComponent },
  {
    path: 'permission-not-found',
    component: PermissionNotFoundComponent,
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
