import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { AuthenticationService } from '../authentication/authentication.service';
import { Observable } from 'rxjs';

import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { AuthGuard } from './auth.guard';
import { AuthState } from '../../store/auth/auth.state';

@Injectable({ providedIn: 'root' })
export class ViewerGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private store: Store,
    private authGuard: AuthGuard,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    // check authGuard, because I want action GetUserById success before
    return this.authGuard.canActivate(route, state).pipe(
      map((isAuth) => {
        if (!isAuth) {
          return false;
        }

        return this.checkPermission(route.params?.id);
      }),
    );
  }

  checkPermission(id?): boolean {
    const canView = this.store.selectSnapshot<boolean>(
      AuthState.canViewBudget(id),
    );
    if (canView) {
      return true;
    } else {
      this.router.navigate(['/page-not-found'], {
        queryParams: { permission: true },
      });
      return false;
    }
  }
}
