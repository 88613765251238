<div class="row">
  <div class="col-12 text-center"
      *ngIf="sendingTaskDetail._state === celeryImportState.Success">
    <h5 class="text-status">{{ (resultTitle || ('REQUEST.SUCCESSFULLY' | translate)) | titlecase }}</h5>
  </div>
  <div class="col-12">
    <ngb-progressbar [type]="currentStateProgressType"
        [striped]="currentStateProgressType === 'info'"
        [animated]="currentStateProgressType === 'info'"
        [value]="(sendingTaskDetail.current_row * 100.0 / sendingTaskDetail.total_row) || 100"
        height="2rem">
      <div class="font-weight-bold">
        <h4 class="mb-0"
            *ngIf="sendingTaskDetail.current_row">
          {{ sendingTaskDetail.current_row }} / {{ sendingTaskDetail.total_row }}
        </h4>
        <h5 class="mb-0 text-white"
            *ngIf="!sendingTaskDetail.current_row">{{ resultState || ('REQUEST.' + sendingTaskDetail._state | translate) }}</h5>
      </div>
    </ngb-progressbar>
  </div>

  <div class="col-12 mt-4"
      *ngIf="sendingTaskDetail._state === celeryImportState.Failure">
    <ngb-alert type="danger"
        [dismissible]="false">
      <h5 class="text-danger">{{ 'REQUEST.ERROR-OCCURRED' | translate }}</h5>
      <div class="frame error p-3"
          [innerHTML]="sendingTaskDetail.detail"></div>
    </ngb-alert>
  </div>
</div>